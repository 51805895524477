import React, {useState} from "react";
import PageContainer from "../../components/PageContainer";
import {CreateNewGalleryDialog} from "./dialogs/CreateNewGalleryDialog";
import {Button, Card, CardActionArea, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useQuery} from "@tanstack/react-query";
import {Api} from "../../api/Api";
import Links from "../../constants/Links";
import Typography from "@mui/material/Typography";
import {Masonry} from '@mui/lab';
import {GalleryDetailsDialog} from "./dialogs/GalleryDetailsDialog";

export default function AdminPanel() {
    const baseUrl = Links.ESERUYANIK_API;

    const [openNewGalleryDialog, setOpenNewGalleryDialog] = useState(false);
    const [openGalleryDetailsDialog, setOpenGalleryDetailsDialog] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState(null)
    const {
        data: galleriesData,
        refetch
    }: any = useQuery({
        queryKey: ['galleries'],
        queryFn: () => {
            return Api.getGalleriesList()
        },
        select: (data: any) => {
            return data
        }
    })

    return (
        <PageContainer title="EserUyanik - Admin Panel">
            <CreateNewGalleryDialog open={openNewGalleryDialog} onClose={setOpenNewGalleryDialog} refetch={refetch}/>
            <GalleryDetailsDialog open={openGalleryDetailsDialog} onClose={setOpenGalleryDetailsDialog}
                                  gallery={selectedGallery} refetchGalleryList={refetch}/>
            <Paper sx={{m: 2}}>
                <Grid container sx={{p: 2}}>
                    <Grid item xs={12}
                          sx={{textAlign: "right", position: "fixed", top: "5em", right: "5em", zIndex: 1}}>
                        <Paper>
                            <Button variant="contained" color="secondary"
                                    onClick={() => setOpenNewGalleryDialog(true)}>
                                Create New Gallery
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{p: 2}}>
                        {galleriesData &&
                            <Masonry columns={{xs: 1, md: 2, lg: 3, xl: 4}}>
                                {galleriesData.map((item: any) => (
                                    <Card className={"MasonryItem"}
                                          sx={{transition: "transform 0.2s ease, width 0.2s ease, height 0.2s ease"}}>
                                        <CardActionArea onClick={() => {
                                            setSelectedGallery(item);
                                            setOpenGalleryDetailsDialog(true);
                                        }}>
                                            <img
                                                width={"100%"}
                                                src={baseUrl + `gallery/${item.id}/cover`}
                                                srcSet={baseUrl + `gallery/${item.id}/cover 2x`}
                                                alt={item.title}
                                                loading="lazy"
                                            />
                                            <Typography variant={"h5"}>
                                                {item.title}
                                            </Typography>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Masonry>
                        }
                    </Grid>
                </Grid>
            </Paper>

        </PageContainer>)
}