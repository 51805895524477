import {
    TypedUseSelectorHook,
    useDispatch as useAppDispatch,
    useSelector as useAppSelector,
} from 'react-redux';
import rootReducer from './reducers/index';
/* Persist Storage */
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import {configureStore} from "@reduxjs/toolkit";


const persistConfig = {
    key: 'root',
    storage,
};

const persistRootReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistRootReducer,
    middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppState = ReturnType<typeof rootReducer>;
export const useDispatch = () => useAppDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
export const persistor = persistStore(store);