import React, {useState} from "react";
import PageContainer from "../../components/PageContainer";
import {useQuery} from "@tanstack/react-query";
import {Api} from "../../api/Api";
import Links from "../../constants/Links";
import {Masonry} from '@mui/lab';
import {ImageListItem} from "@mui/material";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import {useParams} from "react-router-dom";

export default function SingleGallery() {

    const [index, setIndex] = useState<number>(-1);
    const galleryId = useParams().id;
    const {
        data: photographsData,
    }: any = useQuery({
        queryKey: ['photographs'],
        queryFn: () => {
            return Api.getGalleryContent(galleryId);
        },
        select: (data: any) => {
            return data.map((photo: any) => {
                const width = photo.width;
                const height = photo.height;
                return {
                    id: photo.id,
                    src: `${Links.ESERUYANIK_API}photograph/${photo.id}`,
                    width,
                    height,
                };
            });
        },

    })


    return (
        <PageContainer title="EserUyanik Photography">
            <Lightbox
                fullscreen={{auto: true}}
                open={index >= 0}
                close={() => {
                    setIndex(-1);
                }}
                slides={photographsData}
                index={index}
                thumbnails={{
                    showToggle: true,
                    vignette: true,
                    imageFit: "cover",
                }}
                carousel={{
                    padding: 0,
                    spacing: 0,
                }}
                controller={{
                    closeOnPullDown: true,
                    closeOnBackdropClick: true
                }}
                on={{
                    view: ({index}) => {
                        console.log(photographsData ? photographsData[index] : index)
                    }
                }}
                plugins={[Captions, Fullscreen, Thumbnails, Zoom, Counter]}
                styles={{
                    root: {
                        "--yarl__color_backdrop": "#fff",
                        "--yarl__color_button": "#444",
                        "--yarl__color_button_active": "#000",
                        "--yarl__color_button_disabled": "#999",
                        "--yarl__button_filter": "drop-shadow(1px 1px 1px #fff)",
                        "--yarl__counter_filter": "drop-shadow(1px 1px 1px #fff)",
                    },
                    thumbnail: {background: "none"},
                }}
            />

            {photographsData &&
                <Masonry sx={{
                    width: "100vw",
                    p: 0,
                    m: 0,
                    overflowX: "hidden"
                }}
                         columns={{xs: 1, md: 2, lg: 3, xl: 4}}
                         spacing={0}>
                    {photographsData.map((item: any, index: number) => (
                        <ImageListItem key={item.id}
                                       onClick={() => {
                                           setIndex(index);
                                       }}
                                       style={{
                                           padding: 0,
                                           margin: 0,
                                           transition: "transform 0.2s ease, width 0.2s ease, height 0.2s ease",
                                       }}>

                            <img
                                width={"100%"}
                                height={"100%"}
                                src={item.src}
                                alt='eseruyanik'
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </Masonry>
            }
        </PageContainer>
    )
}