import React from "react";
import AdminPanel from "../views/AdminPanel/AdminPanel";
import SidebarLayout from "../layouts/SidebarLayout";
import Home from "../views/Home/Home";
import SingleGallery from "../views/SingleGallery/SingleGallery";

const AuthorizedRoutes = [
    {
        path: '',
        element: <SidebarLayout/>,
        children: [
            {
                path: '',
                element: <Home/>
            },
            {
                path: '/admin',
                element: <AdminPanel/>
            },
            {
                path: 'gallery/:id',
                element: <SingleGallery/>
            }
        ]
    }
];
export default AuthorizedRoutes;