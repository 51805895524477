import React from 'react';
import {Helmet} from 'react-helmet';

type Props = {
    children: JSX.Element | JSX.Element[];
    title?: string;
    style?: any;
}

const PageContainer = ({title, style, children}: Props) => (
    <div style={style}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </div>
);

export default PageContainer;