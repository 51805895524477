import {AuthAction} from "../actions/authActions";

interface AuthState {
    accessToken: string | null;
}

const initialState: AuthState = {
    accessToken: null
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, accessToken: action.payload };
        case 'LOGOUT':
            return { ...state, accessToken: null };
        default:
            return state;
    }
};

export default authReducer;