import {AppBar, Button, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useDispatch} from "../../../redux/store";
import {logout} from "../../../redux/actions/authActions";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
    };
    return (
        <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar>
                <Box onClick={() => navigate("/")} sx={{cursor: "pointer"}}>
                    <Typography variant="h6" noWrap component="div">
                        ESERUYANIK.COM
                    </Typography>
                </Box>
                <Box flexGrow={1}/>

                <Button variant="contained" color="secondary" sx={{alignSelf: "right"}}
                        onClick={() => handleLogout()}>
                    Sign Out
                </Button>
            </Toolbar>
        </AppBar>);
}

export default Header;