import React, {useMemo} from 'react';
import useMeasure from 'react-use-measure';
import {a, useTransition} from '@react-spring/web';

import useMedia from './useMedia';

import styles from './styles.module.css';
import {ImageListItem} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface MasonryProps {
    data: any[],
    setGalleryId: any,
    setGalleryOpen: any,
}

function Masonry({data, setGalleryId, setGalleryOpen}: MasonryProps) {
    // Hook1: Tie media queries to the number of columns

    const columns = useMedia(
        ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
        [4, 3, 2],
        1
    );    // Hook2: Measure the width of the container element
    const [ref, {width}] = useMeasure()

    const navigate = useNavigate();
    // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
    const [heights, gridItems] = useMemo(() => {
        let heights = new Array(columns).fill(0) // Each column gets a height starting with zero
        let gridItems = data.map((child) => {
            const column = heights.indexOf(Math.min(...heights)) // Basic masonry-grid placing, puts tile into the smallest column using Math.min
            const x = (width / columns) * column // x = container width / number of columns * column index,
            const y = (heights[column] += (child.height * width) / (child.width * columns)) - (child.height * width) / (child.width * columns) // y = it's just the height of the current column
            return {...child, x, y, width: width / columns, height: (child.height * width) / (child.width * columns)}
        })
        return [heights, gridItems]
    }, [columns, data, width])
    // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
    const transitions = useTransition(gridItems, {
        key: (item: { css: string; height: number }) => item.css,
        from: ({x, y, width, height}) => ({x, y, width, height, opacity: 0}),
        enter: ({x, y, width, height}) => ({x, y, width, height, opacity: 1}),
        update: ({x, y, width, height}) => ({x, y, width, height}),
        leave: {height: 0, opacity: 0},
        config: {mass: 5, tension: 100, friction: 30},
        trail: 50,
    })
    // Render the grid
    return (
        <div ref={ref} className={styles.list}
             style={{height: Math.max(...heights), width: '100%', padding: 0, margin: 0}}>
            {transitions((style, item) => (
                <a.div style={style}>
                    <ImageListItem key={item.id}
                                   onClick={() => {
                                       //navigate(`/gallery/${item.id}`);
                                       setGalleryId(item.id);
                                       setGalleryOpen(true);
                                   }}
                                   className={"hover-effect"}
                                   style={{
                                       padding: 0,
                                       margin: 0,
                                       transition: "transform 0.2s ease, width 0.2s ease, height 0.2s ease",
                                   }}>
                        <img
                            width={"100%"}
                            height={"100%"}
                            src={item.css}
                            alt={item.title}
                            loading="lazy"
                        />
                        <div className={"overlay"} style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fff",
                        }}
                        />
                    </ImageListItem>
                </a.div>
            ))}
        </div>
    )
}

export default Masonry;
